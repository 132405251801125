<template>
  <div>
    <v-row
      justify="end"
      align="center"
      no-gutters
      class="mb-2"
    >
      <v-col>
        <v-alert
          text
          prominent
          icon="mdi-information-outline"
          color="primary"
          class="mb-0"
        >
          <v-row align="center" no-gutters>
            <v-col>
              <span
                class="headline ml-5"
                v-text="$t('criticalchain.selectCriticalChain.message')"
              />
            </v-col>
            <v-col cols="auto">
              <v-btn
                :disabled="loading"
                :loading="loadingPreviousStep"
                class="mx-1"
                color="primary"
                outlined
                @click="previousStep"
                v-text="$t('general.previous')"
              />
              <v-btn
                :disabled="loading"
                :loading="loadingSelectCriticalChain"
                class="mx-1"
                color="accent"
                @click="selectCriticalChain"
                v-text="$t('general.next')"
              />
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>
    <frappe-gantt
      v-model="internalTaskList"
      select-key="isOnCriticalChain"
      selectable
      fixed
    />
  </div>
</template>

<script>
import FrappeGantt from '../components/FrappeGantt'
import AVAILABLE_ACTIONS from '../availableActions'

export default {
  name: 'SelectCriticalChainContainer',
  components: {
    FrappeGantt,
  },
  props: {
    currentPlayer: {
      type: Object,
      required: true,
    },
  },
  inject: [
    'sendPlayerAction',
  ],
  data () {
    return {
      internalTaskList: [],
      loadingSelectCriticalChain: false,
      loadingPreviousStep: false,
    }
  },
  computed: {
    loading () {
      return this.loadingSelectCriticalChain || this.loadingPreviousStep
    },
  },
  watch: {
    currentPlayer: {
      immediate: true,
      handler () {
        const taskList = JSON.parse(JSON.stringify(this.currentPlayer.taskList))
        const bufferLabel = this.currentPlayer.bufferSize || this.$t('criticalchain.bufferNotDefined')
        this.internalTaskList = taskList.map(item => (item.id === 'Buffer'
          ? { ...item, name: `${item.id} (${bufferLabel})` }
          : item))
      },
    },
  },
  methods: {
    async selectCriticalChain () {
      try {
        this.loadingSelectCriticalChain = true
        await this.sendPlayerAction(AVAILABLE_ACTIONS.selectCriticalChain, {
          taskList: this.internalTaskList,
        })
      }
      finally {
        this.loadingSelectCriticalChain = false
      }
    },
    async previousStep () {
      try {
        this.loadingPreviousStep = true
        await this.sendPlayerAction(AVAILABLE_ACTIONS.previousStep)
      }
      finally {
        this.loadingPreviousStep = false
      }
    },
  },
}
</script>
